import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Row, Col, Select, Typography } from 'antd';

import { WorkOrderStatusEnum, WorkOrderRepairPriorityEnum, WorkOrderTypeOfServiceEnum } from 'stores/Classes/WorkOrderObject';
import CurrentUserStore from 'stores/CurrentUserStore';
import { TableStore } from 'stores/abstract';
import {
  ShopStore,
} from 'stores';

import { UserStore, WorkOrderStore } from 'stores';
import { customFleets } from 'shared';

const { Option } = Select;
const { Text } = Typography;


const fullWidth = {
  width: '100%'
};

const randomWorkOrderNumber = () => {
  // generate a random number between 1 and 1000000
  // and pad it with 0s to make it 6 digits long
  const randomNumber = `000000${Math.floor(Math.random() * 1000000)}`;
  return `${randomNumber.substring(randomNumber.length - 6)}`;
};

class WorkOrderTMTDetailsForm extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    childRef: PropTypes.func,
    onSave: PropTypes.func,
    defects: PropTypes.array,
    tmtRepairShop: PropTypes.string
  };

  state = {
    users: [],
    complaints: ["Complaint 1", "Complaint 2", "Complaint 3", "BROKEN"],
    componentCodes: ["Code 1", "Code 2", "Code 3", "002-036"],
    repairReasons: ["Repair Reason 1", "Repair Reason 2", "Repair Reason 3", "BREAKDOWN"]
  }

  usersTableStore = new TableStore()

  async componentDidMount () {
    // To disable submit button at the beginning.
    const { childRef } = this.props;
    if (childRef) {
      childRef(this);
    }

    await this.loadShopUsers();
    await this.loadComponentCodes();
    await this.loadComplaintCodes();
    await this.loadRepairCodes();
  }

  get complaintOptions () {
    return this.state.complaints.map((complaint, index) => (
      <Option
        key={index}
        value={complaint.code}
      >
        {`${complaint.code}` || "Break"}
      </Option>
    ));
  }

  get componentCodeOptions () {
    return this.state.componentCodes.map((compCode, index) => (
      <Option
        key={index}
        value={compCode.code}
      >
        {`${compCode.code}: ${compCode.description}` || "No Code"}
      </Option>
    ));
  }

  get repairReasonOptions () {
    return this.state.repairReasons.map((repReason, index) => (
      <Option
        key={index}
        value={repReason.code}
      >
        {`${repReason.code}` || "No Repair Reason"}
      </Option>
    ));
  }

  get createdByOptions () {
    return this.state.users.map((user, index) => (
      <Option
        key={index}
        value={user.userId}
      >
        {`${user.firstName} ${user.lastName}`.trim() || user.email}
      </Option>
    ));
  }

  get assignedToOptions () {
    const users = this.state.users.map((user, index) => (
      <Option
        key={index}
        value={user.userId}
      >
        {`${user.firstName} ${user.lastName}`.trim() || user.email}
      </Option>
    ));
    return users;
  }

  get priorityOptions () {
    return Object.keys(WorkOrderRepairPriorityEnum)
    .map(woRepairPriorityKey => ({
      key: woRepairPriorityKey,
      value: WorkOrderRepairPriorityEnum[woRepairPriorityKey]
    }))
    .map(woRepairPriority => {
      return(
        <Option
          key={woRepairPriority.key}
          value={woRepairPriority.key}
        >
          {woRepairPriority.value}
        </Option>
      )
    })
  }

  get typeOptions () {
    return Object.keys(WorkOrderTypeOfServiceEnum)
      .map(woRepairPriorityKey => ({
        key: woRepairPriorityKey,
        value: WorkOrderTypeOfServiceEnum[woRepairPriorityKey]
      }))
      .map(woRepairPriority => {
        return (
          <Option
            key={woRepairPriority.key}
            value={woRepairPriority.key}
          >
            {woRepairPriority.value}
          </Option>
        );
      });
  }

  get statusOptions() {
    return Object.keys(WorkOrderStatusEnum)
    .map((workOrderStatusKey) => ({
      key: workOrderStatusKey,
      value: WorkOrderStatusEnum[workOrderStatusKey],
    }))
    .map((workOrderStatus) => {
      return (
        <Option key={workOrderStatus.key} value={workOrderStatus.key}>
          {workOrderStatus.value}
        </Option>
      );
    })
  };

  filterOption = (input, option) => {
    return option.props.children
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0;
  };

  initialWorkOrderNumber = randomWorkOrderNumber();

  loadShopUsers = async () => {
    const users = await UserStore.fetchUsersForTable({
      offset: 0,
      limit: 100,
      filter: null
    });

    this.setState({ users });
  }

  // Refactor This when Summit Send the values
  loadComponentCodes = async () => {
    const componentCodes = await WorkOrderStore.getTMTComponentCodes();
    this.setState({ componentCodes });
  }

  loadComplaintCodes = async () => {
    const complaintCodes = await WorkOrderStore.getTMTComplaintCodes();
    this.setState({ complaints: complaintCodes });
  }

  loadRepairCodes = async () => {
    const repairCodes = await WorkOrderStore.getTMTRepairCodes();
    this.setState({ repairReasons: repairCodes });
  }

  getDefectComponentCode = (defect) => {
    const initialCompCode = this.state.componentCodes.find(comp => comp.defect === defect);
    return initialCompCode ? `${initialCompCode.code}` : undefined;
  }

  getCurrentLoggedUser = () => {
    return CurrentUserStore.user.id;
  }

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave(true);
    }
  }

  woInputError = (input) => {
    const { getFieldError, isFieldTouched } = this.props.form;
    return isFieldTouched(input) && getFieldError(input);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

      return (
      <Form onSubmit={this.onSave}>
          <Row style={{ marginTop: 15 }} gutter={24}>
            <Col span={12}><Text strong>Work Order Details</Text></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
            {customFleets.summitMaterialsAll.includes(ShopStore.currentShop.id)&& <div><Text strong>TMT Repair Shop: </Text><Text>{this.props.tmtRepairShop}</Text></div>}
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }} gutter={16}>
            <Col span={8}>
              <Form.Item label="Work order number:" validateStatus={this.woInputError('woInvoiceNumber') ? 'error' : ''} help={this.woInputError('woInvoiceNumber') || ''}>
                {getFieldDecorator('woInvoiceNumber', {
                  rules: [{ required: true, message: 'Work order number is required!' }],
                  initialValue: this.initialWorkOrderNumber
                })(
                  <Input
                    prefix="#"
                    allowClear={true}
                    placeholder="#000000"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Created by:" validateStatus={this.woInputError('woCreatedBy') ? 'error' : ''} help={this.woInputError('woCreatedBy') || ''}>
                {getFieldDecorator('woCreatedBy', {
                  rules: [{ required: true, message: 'Created by is required!' }],
                  initialValue: this.getCurrentLoggedUser()
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select a User"
                    showSearch
                    optionFilterProp="children"
                    filterOption={this.filterOption}
                  >
                    {this.createdByOptions}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Assigned To:" validateStatus={this.woInputError('woAssignedTo') ? 'error' : ''} help={this.woInputError('woAssignedTo') || ''}>
                {getFieldDecorator('woAssignedTo', {
                  rules: [{ required: true, message: 'Assigned to is required!' }],
                  initialValue: this.getCurrentLoggedUser(),
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select a User"
                    showSearch
                    optionFilterProp="children"
                    filterOption={this.filterOption}
                  >
                    {this.assignedToOptions}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Status:" validateStatus={this.woInputError('woStatus') ? 'error' : ''} help={this.woInputError('woStatus') || ''}>
                {getFieldDecorator('woStatus', {
                  initialValue: 'open',
                  rules: [{ required: true, message: 'Status is required!' }],
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select a Status"
                    showSearch
                    optionFilterProp="children"
                    filterOption={this.filterOption}
                  >
                    {this.statusOptions}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Repair Type:" validateStatus={this.woInputError('woRepairType') ? 'error' : ''} help={this.woInputError('woRepairType') || ''}>
                {getFieldDecorator('woRepairType', {
                  initialValue: 'driver_identified',
                  rules: [{ required: true, message: 'Repair Type is required!' }],
                })(
                  <Select style={fullWidth}
                    placeholder="Select a Repair Type"
                    showSearch
                    optionFilterProp="children"
                    filterOption={this.filterOption}
                    disabled={true}
                  >
                    {this.typeOptions}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Repair Priority:" validateStatus={this.woInputError('woRepairPriority') ? 'error' : ''} help={this.woInputError('woRepairPriority') || ''}>
                {getFieldDecorator('woRepairPriority', {
                  initialValue: 'major',
                  rules: [{ required: true, message: 'Repair Priority is required!' }],
                })(
                  <Select style={fullWidth}
                    placeholder="Select a Repair Priority"
                    showSearch
                    optionFilterProp="children"
                    filterOption={this.filterOption}
                  >
                    {this.priorityOptions}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text strong style={{ marginBottom: 10, display: 'block' }}>Defects that will be added to this Work Order</Text>
              {this.props.defects.map((issue, index) => (
                <Text key={issue.id}>
                  <Text underline>{issue.defectType}: {issue.comment}</Text>
                  <Row style={{ marginTop: 15 }} gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Component Code:" validateStatus={this.woInputError(`componentCode_${index}`) ? 'error' : ''} help={this.woInputError(`componentCode_${index}`) || ''}>
                        {getFieldDecorator(`componentCode_${index}`, {
                          rules: [{ required: true, message: `Component Code number for ${issue.defectType} is required!` }],
                          initialValue: this.getDefectComponentCode(issue.defectType) || undefined
                        })(
                          <Select
                            style={fullWidth}
                            placeholder="No Matching Value Found"
                            showSearch
                            optionFilterProp="children"
                            filterOption={this.filterOption}
                          >
                            {this.componentCodeOptions}
                          </Select>,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Complaint:" validateStatus={this.woInputError(`complaint_${index}`) ? 'error' : ''} help={this.woInputError(`complaint_${index}`) || ''}>
                        {getFieldDecorator(`complaint_${index}`, {
                          rules: [{ required: true, message: 'Complaint is required!' }],
                          initialValue: "BROKEN"
                        })(
                          <Select
                            style={fullWidth}
                            placeholder="Select a Component Code"
                            showSearch
                            optionFilterProp="children"
                            filterOption={this.filterOption}
                          >
                            {this.complaintOptions}
                          </Select>,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Repair Reason:" validateStatus={this.woInputError(`repairReason_${index}`) ? 'error' : ''} help={this.woInputError(`repairReason_${index}`) || ''}>
                        {getFieldDecorator(`repairReason_${index}`, {
                          rules: [{ required: true, message: 'Repair Reason is required!' }],
                          initialValue: "DVIR"
                        })(
                          <Select
                            style={fullWidth}
                            placeholder="Select a Repair Reason"
                            showSearch
                            optionFilterProp="children"
                            filterOption={this.filterOption}
                          >
                            {this.repairReasonOptions}
                          </Select>,
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Text>
              ))}
            </Col>
          </Row>
      </Form>
    );
  }
}

const WorkOrderTMTForm = Form.create({ name: 'work_order_form' })(WorkOrderTMTDetailsForm);

export default WorkOrderTMTForm;