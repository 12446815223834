import { CarStore, ShopStore } from 'stores';
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Table, Typography, Button, Select, Icon } from 'antd';
const { Text } = Typography;
const { Option } = Select;

const DVIRModal = ({ shopId, isTrailer, visible, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({
    safe: 0,
    unsafe: 0,
    resolved: 0,
  });
  const [modalData, setModalData] = useState([]);
  const [timeZone, setTimeZone] = useState('EST'); // Default time zone
  const [timeRange, setTimeRange] = useState({ startOfDay: '', endOfDay: '' }); // Time range from API

  useEffect(() => {
    if (visible) {
      fetchModalData();
    }
  }, [visible, timeZone]);

  const fetchModalData = async () => {
    setIsLoading(true);
    const shopId = ShopStore.currentShop.id;
  
    try {
      const response = await CarStore.fetchDVIRsDetails(shopId, timeZone);
  
      if (!response || !response.results) {
        console.error('Invalid response structure:', response);
        setModalData([]);
        setStats({ safe: 0, unsafe: 0, resolved: 0 });
        setTimeRange({ startOfDay: '', endOfDay: '' });
        return;
      }
  
      // Extract time range from API response
      setTimeRange({
        startOfDay: response.startOfDay,
        endOfDay: response.endOfDay,
      });
  
      // Extract stats
      const safeCount = parseInt(response.results.find(r => r.safety_status === 'safe')?.count || 0, 10);
      const unsafeCount = parseInt(response.results.find(r => r.safety_status === 'unsafe')?.count || 0, 10);
  
      const resolvedData = response.results.find(r => r.safety_status === 'resolved');
      const resolvedCount = parseInt(resolvedData?.count || 0, 10);
      const resolvedDetails = resolvedData?.dvir_details || [];
  
      setStats({
        safe: safeCount,
        unsafe: unsafeCount,
        resolved: resolvedCount,
      });
  
      // Extract and flatten "all" safety_status details
      const allDetails = response.results.find(r => r.safety_status === 'all')?.dvir_details || [];
      const flattenedDetails = allDetails.flat(); // Flatten the nested arrays
  
      // Filter out invalid entries
      const validDetails = flattenedDetails.filter(detail => detail && detail.safetyStatus);
  
      const formattedData = validDetails.map(detail => {
        const isResolved = detail.safetyStatus === 'resolved';
  
        return {
          id: detail.id,
          type: detail.type === 'preTrip' ? 'Pre-Trip' : 'Post-Trip',
          vehicle: detail.vehicle?.name || detail.trailer?.name || 'N/A',
          author: detail.authorSignature?.signatoryUser?.name || 'Unknown',
          signedAt: isResolved
            ? new Date(detail.authorSignature?.signedAtTime).toLocaleString('en-US', { timeZone: 'America/New_York' })
            : new Date(detail.endTime).toLocaleString('en-US', { timeZone: 'America/New_York' }),
          duration: isResolved
            ? `${Math.round((new Date(detail.secondSignature?.signedAtTime) - new Date(detail.dvirSubmissionBeginTime)) / 60000)} mins`
            : `${Math.round((new Date(detail.endTime) - new Date(detail.startTime)) / 60000)} mins`,
          defects: detail.trailerDefects?.length || detail.defectIds?.length || 0,
          status: detail.safetyStatus?.toUpperCase() || 'UNKNOWN',
        };
      });
  
      setModalData(formattedData);
    } catch (error) {
      console.error('Failed to fetch DVIR details:', error);
      setModalData([]);
      setStats({ safe: 0, unsafe: 0, resolved: 0 });
      setTimeRange({ startOfDay: '', endOfDay: '' });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (text) => text,
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Signed At',
      dataIndex: 'signedAt',
      key: 'signedAt',
    },
    {
      title: 'Defects',
      dataIndex: 'defects',
      key: 'defects',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'UNSAFE' ? 'red' : 'green' }}>
          {status}
        </span>
      ),
    },
  ];

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
  {/* Title and Controls */}
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontWeight: 'bold', marginRight: 16 }}>DVIR Details</span>

    {/* Time Zone Dropdown */}
    <Select
      value={timeZone}
      style={{ width: 120 }}
      onChange={(value) => setTimeZone(value)}
    >
      <Option value="EST">EST</Option>
      <Option value="CST">CST</Option>
      <Option value="MST">MST</Option>
      <Option value="PST">PST</Option>
      <Option value="EDT">EDT</Option>
      <Option value="PDT">PDT</Option>
    </Select>

    {/* Reload Button */}
    <Button
      type="default"
      onClick={fetchModalData}
      loading={isLoading}
      icon="reload"
      style={{ marginLeft: 10 }}
    />
  </div>
</div>

      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
      confirmLoading={isLoading}
    >
      <div style={{ margin: '20px', textAlign: 'left' }}>
        <div style={{ marginBottom: '10px' }}>
          <strong>
            This module displays all DVIRs from the past 24 hours, including vehicles and trailers. Use this data to compare DVIRs with what's shown on your Samsara dashboard.
          </strong>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <div>
            <Text>
              <Text strong>Last 24 Hours:</Text> {`Start: ${timeRange.startOfDay} (${timeZone}) | End: ${timeRange.endOfDay} (${timeZone})`}
            </Text>
          </div>
        </div>

        {/* Stats Row */}
        <Row gutter={16}>
          <Col span={6}>
            <Text strong>All</Text>
            <div>{stats.safe + stats.unsafe}</div>
          </Col>
          <Col span={6}>
            <Text strong>Safe</Text>
            <div>{stats.safe}</div>
          </Col>
          <Col span={6}>
            <Text strong>Unsafe</Text>
            <div>{stats.unsafe}</div>
          </Col>
          <Col span={6}>
            <Text strong>Resolved</Text>
            <div>{stats.resolved}</div>
          </Col>
        </Row>
      </div>
      <Table
        dataSource={modalData}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={{ pageSize: 5 }}
        loading={isLoading}
      />
    </Modal>
  );
};

export default DVIRModal;
